<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showModal"
    >   
        <CForm id="raffle_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="6">
                <CInput
                  label="Nombre:"
                  :lazy="false"
                  name="name"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                  placeholder=""
                  autocomplete="given-name"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
              <!--
              <CCol md="3">
                <CSelect
                    label="Color"
                    id="required"
                    name="required"
                    :value.sync="$v.form.color.$model"
                    :options="[{label: 'Rojo', value: '#800000'},
                      {label: 'Verde', value: '#008000'}, 
                      {label: 'Azul', value: '#000080'}, 
                      {label: 'Amarillo', value: '#B0B000'}, 
                      {label: 'Naranja', value: '#D08000'},
                      {label: 'Morado', value: '#800080'},
                      {label: 'Gris', value: '#808080'}]"
                    @change="changeTickets()"
                  />
              </CCol>
              -->
              <CCol md="6">
                <CInput
                  label="Premio:"
                  :lazy="false"
                  name="prize"
                  :value.sync="$v.form.prize.$model"
                  :isValid="checkIfValid('prize')"
                  placeholder=""
                  autocomplete="given-prize"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
              </CCol>
            </CRow>
            <!--
            <CRow>
              <CCol md="12">
                <label>Descripción:</label>
                <CTextarea v-model="form.description" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                  id="description"
                  name="description"
                  rows="3"
                  text="Must be 8-20 words long."
                ></CTextarea>
              </CCol>
            </CRow>
            -->
          <CRow>
            <CCol md="6">
              <div role="group" class="form-group">
                <label>Fecha:</label>
                <v-date-picker
                  id="date"
                  name="date"
                  class="date-picker"
                  v-model="form.date"
                  locale="es-MX" size="sm"
                />
              </div>
            </CCol>
            <CCol md="6">
                <CInput
                  label="Precio:"
                  :lazy="false"
                  name="price"
                  :value.sync="$v.form.price.$model"
                  :isValid="checkIfValid('price')"
                  placeholder=""
                  autocomplete="given-price"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                />
            </CCol>
            <CCol md="4">
                <CSelect
                    label="Rango de Números"
                    id="numbers"
                    name="numbers"
                    :value.sync="$v.form.numbers.$model"
                    :options="[{label: '0 - 9', value: '10'}, {label: '00 - 99', value: '100'}, {label: '000 - 999', value: '1000'}, {label: '0000 - 9999', value: '10000'}, {label: '00000 - 99999', value: '100000'}]"
                    @change="changeTickets()"
                    :disabled="form.id > 0"
                  />
            </CCol>
            <CCol md="4">
                <CSelect
                    label="Números por Boleto"
                    id="options"
                    name="options"
                    :value.sync="$v.form.options.$model"
                    :options="[{label: '1', value: '1'}, {label: '2', value: '2'}, {label: '3', value: '3'}, {label: '4', value: '4'}, {label: '5', value: '5'}]"
                    @change="changeTickets()"
                    :disabled="form.id > 0"
                  />
            </CCol>
            <CCol md="4">
                <CInput
                  label="Total de Boletos:"
                  :lazy="false"
                  name="tickets"
                  :value.sync="$v.form.tickets.$model"
                  placeholder=""
                  autocomplete="given-tickets"
                  invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                  :disabled="form.id > 0"
                />
            </CCol>
          </CRow>
          <CRow>
              <CCol md="12">
                <label>Fotografías del Premio:</label>
                <CInput v-model="form.image_1" type="file" id="image_1" name="image_1" label="" label="" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                <CInput v-model="form.image_2" type="file" id="image_2" name="image_2" label="" label="" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                <CInput v-model="form.image_3" type="file" id="image_3" name="image_3" label="" label="" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                <CInput v-model="form.image_4" type="file" id="image_4" name="image_4" label="" label="" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                <CInput v-model="form.image_5" type="file" id="image_5" name="image_5" label="" label="" style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"
import raffles from '../../../services/raffles';

import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 

export default {
  name: 'RaffleModal',
  components: {
    'v-date-picker': DatePicker
  },
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Nuevo Sorteo",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Permisos del Acceso'},
        {key: 'actions', label: '', class: 'actions' }
      ]
    }
  },
  mounted: async function() {
    
  },
  methods: {
    changeTickets(){
      console.log(this.form.numbers);
      console.log(this.form.options);
      
        this.form.tickets = Math.ceil(this.form.numbers / this.form.options);

        console.log(this.form.tickets);
    },
    storeModal () {
      this.showModal = true;
      this.color = "info";
      this.title = 'Nuevo Sorteo';
      this.isEdit = false;
      this.submitted = false;

      this.form = {
          id: 0,
          name: '',
          color: '#800000',
          prize: '',
          description: '',
          date: today.toDate(),
          price: '50.00',
          numbers: "100",
          options: "1",
          tickets: "100",
          image_1: "",
          image_2: "",
          image_3: "",
          image_4: "",
          image_5: ""
      }
    },
    updateModal (item) {
      this.showModal = true;
      this.color = "warning";
      this.title = 'Editar Sorteo';
      this.isEdit = true;
      this.submitted = false;

      let date = item.date != null ? moment(item.date, "YYYY-MM-DD") : moment().add(4, 'weeks').endOfWeek().subDays(2);

      this.form = {
          id: item.id,
          name: item.name,
          prize: item.prize,
          description: '',
          date: date.toDate(),
          price: (item.ticket_price * 1).toFixed(2),
          numbers: item.ticket_numbers,
          options: item.ticket_options,
          tickets: item.tickets,
          image_1: "",
          image_2: "",
          image_3: "",
          image_4: "",
          image_5: ""
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        const form = document.getElementById("raffle_form");
        const formData = new FormData(form);

        if(this.form.date){
          let date = new moment(this.form.date);
          formData.append("date", date.format("YYYY-MM-DD"));
        }

        if(this.form.id > 0){
          formData.append("id", this.form.id);

          this.$emit("update", formData );
        }
        else{
          this.$emit("store", formData );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.showModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      prize: {
        required,
        minLength: minLength(3)
      },
      price: {
        required
      },
      tickets: {
        required
      },
      numbers: {},
      options: {},
    }
  },
}
</script>