<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Sorteo</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #id="{item}"><td class="text-center w100">{{item.id}}</td></template>
        <template #name="{item}"><td>{{item.name}}</td></template>
        <template #tickets="{item}"><td class="text-center w200">
        {{item.tickets | number}} boletos
        <br />
        {{item.ticket_options > 1 ? item.ticket_options+' números' : item.ticket_options+' número'}} <br /> Precio: ${{item.ticket_price | currency}}</td></template>
        <template #url="{item}"><td class="text-center w150"><a :href="'/#/'+item.code" target="_blank">Ver Rifa</a></td></template>
        <template #gamblers="{item}"><td class="text-center w150"><a :href="'/#/control/'+item.code+'/gamblers'">Ver Participantes</a></td></template>
        <template #reservations="{item}"><td class="text-center w150"><a :href="'/#/control/'+item.code+'/reservations'">Ver Reservados</a></td></template>
        <template #permissions="{item}"><td class="text-left"><li v-for="(permission, index) in item.permissions">{{showPermissionName(index)}}</li></td></template>
        <template #activations="{item}">
          <td class="table_actions">
            <CButton color="success" size="sm" @click="deactiveModal(item)" v-if="item.is_active"><CIcon name="cil-check"/></CButton>
            <CButton color="danger" size="sm" @click="activeModal(item)" v-if="!item.is_active"><CIcon name="cil-ban"/></CButton>
          </td>
        </template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <ActiveModal ref="activeModal" @active="activeProcess" @deactive="deactiveProcess"></ActiveModal>
    <RaffleModal ref="raffleModal" @store="storeProcess" @update="updateProcess"></RaffleModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import RaffleModal from './RaffleModal.vue'
import DeleteModal from '../../global/DeleteModal.vue'
import ActiveModal from './ActiveModal.vue'
import ws from '../../../services/raffles';
import store from '../../../store'

export default {
  name: 'RafflesTable',
  components: { RaffleModal, DeleteModal, ActiveModal },
  props: {
    items: Array,
    permissions: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'id', label: 'Edición'},
          {key: 'name', label: 'Nombre'},
          {key: 'tickets', label: 'Boletos'},
          {key: 'url', label: 'URL de la Rifa'},
          {key: 'reservations', label: 'Reservados'},
          {key: 'gamblers', label: 'Participantes'},
          {key: 'activations', label: '¿Activo?', class: 'actions' },
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    activeModal (item) {
      this.$refs.activeModal.openModal(item);
    },
    deactiveModal (item) {
      this.$refs.activeModal.openModal(item);
    },
    storeModal () {
      this.$refs.raffleModal.storeModal();
    },
    updateModal (item) {
      this.$refs.raffleModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Sorteo", item.id, item.name);
    },
    async storeProcess(data) {
      this.loading();
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async updateProcess(data) {
      this.loading();
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deleteProcess(id) {
      this.loading();
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async activeProcess(data) {
      this.loading();
      let response = await ws.active(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    async deactiveProcess(data) {
      this.loading();
      let response = await ws.deactive(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
      this.loaded();
    },
    showPermissionName(permission_code){
      let perm = this.$findElementByCode(this.permissions, permission_code);
      return perm ? perm.name : '';
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
