<template>
    <CModal
      :title="title"
      :color="color"
      :show.sync="showModal"
    >
      <p style="font-weight: bold;" v-if="raffle.is_active">¿Estás seguro que deseas cerrar el sorteo?</p>
      <p style="font-weight: bold;" v-if="!raffle.is_active">¿Estás seguro que deseas volver a abrir el sorteo?</p>
      <br>
      <p v-if="raffle.is_active">Un sorteo cerrado ya no será visible para los clientes y ya nadie podrá comprar boletos.</p>
      <p v-if="!raffle.is_active">Reabrir el sorteo lo volverá a hacer visible y los clientes podrán volver a comprar boletos.</p>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar</CButton>
          <CButton @click="deactive" color="danger" v-if="raffle.is_active">Cerrar Sorteo</CButton>
          <CButton @click="active" color="success" v-if="!raffle.is_active">Abrir Sorteo</CButton>
        </template>
    </CModal>
</template>

<script>
import raffles from '../../../services/raffles';

import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 

export default {
  name: 'ActiveModal',
  components: {
    'v-date-picker': DatePicker
  },
  props: {

  },
  data () {
    return {
      showModal: false,
      title: "Reabrir Sorteo",
      color: "info",
      raffle: false
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal (item) {
      this.showModal = true;
      if(!item.is_active){
        this.color = "info";
        this.title = 'Reabrir Sorteo';
      }
      else{
        this.color = "warning";
        this.title = 'Cerrar Sorteo';
      }

      this.raffle = item;
    },
    active () {
      this.$emit("active", this.raffle );

      this.closeModal();
    },
    deactive () {
      this.$emit("deactive", this.raffle );

      this.closeModal();
    },
    closeModal () {
      this.showModal = false;
    },
  },
  computed: {

  },
}
</script>